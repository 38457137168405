import Link from 'next/link'
import React from 'react'
import { Row, Col } from 'react-bootstrap'

const WODExpansion = () => (
  <>
    <div className="home-detailed-text-section">
      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Play on Warlords of Draenor Private Servers</h2>
          <div className="detailed-description">
            <p>Are you searching for a WoD Private Server? You might wonder if there are even any private servers available for World of Warcraft: Warlords of Draenor? Warlords of Draenor was released on November 13, back in 2014 by Blizzard. To answer your question, at Zremax, we have compiled you a great list of all the WoD Private Servers with a lot of information, which includes realm-type, population, server-language, and more. You can also filter by your preferences, to find the right 6.x WoD Server for you.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Best WoD Private Servers 2025</h2>
          <div className="detailed-description">
            <p>Let’s retrieve the best WoD servers for you in 2025.</p>
            <p>We will be answering you on the question: “What is the Best WoD Private Server around?”.</p>
            <p>Currently, there aren’t many WoD WoW Servers around. The reason is simple – the majority of the players don’t like the expansion. Because there aren’t many players searching for WoD, there aren’t many <Link href="/wow/private-servers/">WoW Private Servers</Link> who offer it.&nbsp; As of currently, we can only recommend one server as the Best WoD Private Server – simply because it’s the only server around. That is Firestorm’s WoD private Server. It has a small population and a small base. If you are really eager to try the 6.x patch, we recommend you Firestorm.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >WoW WoD Server List</h2>
          <div className="detailed-description">
            <p>As you have most likely figured out by now, we list you with all the WoD private servers, through our updated list. We want to make sure you find your next server. We believe that is done through a proper list that is being updated regularly and contains an honest list of WoD Servers to play on. Keep in mind that there however aren’t many WoD WoW Servers on our list as of right now. That’s not because we don’t update the page, but simply because there just aren’t many WoD servers around. The new one’s opening tends to close very shortly again, due to a lack of interest and population from the WoD community. A lot of people believe that World of Warcraft: Warlords of Draenor isn’t worth the hassle. The reason is that a lot of players dislike WoD and have no interest in playing it.</p>
            <p>It is exactly the same case with <Link href="/wow/addons/wod-addons">WoD WoW Addons</Link> where the collection is also rather limited due to the popularity of WoD.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Conclusion</h2>
          <div className="detailed-description">
            <p>In the end, it’s safe to conclude there aren’t many WoD Private Servers available to play on. If you really want to experience the patch, you can try Firestorm which offers a World of Draenor Server. It however has a low population and is not very active, but it lets you play the WoW WoD content.</p>
          </div>
        </Col>
      </Row>
    </div>
  </>
)

export default WODExpansion
