import React from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import Image from 'next/image'

const HomeBanner = () => {
  const onReadMore = () => {
    document.getElementById('expansion-description')?.scrollIntoView({ behavior: 'smooth' })
  }

  const onPlayGame = () => {
    document.getElementById('home-listing-title')?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
    <div className="home-banner">
      <Image
        layout="fill"
        objectFit="cover"
        alt="Banner"
        placeholder='blur'
        src={require('../../../public/images/banners/home-banner.png')}
        style={{
          zIndex: -2
        }}
        priority
      />
      <div className="home-banner_content">
        <Container>
          <div className="home-banner_content_inner">
            <Row>
              <Col xs={12} lg={12}>
                <div className="home-banner_content_text text-center">
                  <h1 className="home-banner_content_header zx-brightness-120 zx-transition-03">Game Servers Toplist</h1>
                  <div className="home-banner_content_subheader mb-3"><span className="underline-custom">Community, for players!</span></div>
                  <p className="home-banner_content_paragraph mx-auto">Zremax is the main platform for everything related to Game Servers. You can find servers, guides, resources, tools and debate here!</p>
                  <div className="home-banner_content_actions mx-auto" style={{ justifyContent: 'center' }}>
                    <Button className="zx-btn zx-btn-custom-padding zx-btn-blue zx-transform-scale-11 zx-transition-03" variant="primary" onClick={() => onReadMore()}>Read More</Button>
                    <Button className="zx-btn zx-btn-custom-padding zx-btn-yellow zx-text-color-black zx-transform-scale-11 zx-transition-03" variant="warning" onClick={() => onPlayGame()}>Find Servers</Button>
                  </div>
                </div>
              </Col>
              {/* <Col xs={4}>
                <div className="home-banner_content_images">
                  <div className="home-banner_content_images_king zx-transition-03 zx-brightness-130 zx-transform-scale-11">
                    <Image layout="fill" objectFit="cover" alt="King" src={require('../../../public/images/characters/king.png')} placeholder='blur'/>
                  </div>
                  <div className="home-banner_content_images_windrunner zx-transition-03 zx-brightness-130 zx-transform-scale-11">
                    <Image layout="fill" objectFit="cover" alt="Wind Runner" src={require('../../../public/images/characters/windrunner.png')} placeholder='blur'/>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        </Container>
      </div>
    </div>
    </>
  )
}

export default HomeBanner
