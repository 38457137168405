import Link from 'next/link'
import React from 'react'
import { Row, Col } from 'react-bootstrap'

const DragonflightExpansion = () => (
  <>
    <div className="home-detailed-text-section">
      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Why should you consider playing on a Dragonflight Private Server?</h2>
          <div className="detailed-description">
           <p>The main reason for playing on a Dragonflight Private Server right now is that you will be able to experience the current expansion of World of Warcraft for no cost. If you enjoy experiencing the latest version of the game, this can be a good reason.
            <br/> The downside of playing on such a new expansion through a Private server is however that the amount of bugs will be much higher compared to for instance a <Link className="a-underline-none" href="/wow/private-servers/wotlk-private-server/">Wotlk Private Server</Link>. Because of that, you have to decide whether you value playing on the newest content higher than playing with less bugs and issues.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Are there any Private Servers for Dragonflight available?</h2>
          <div className="detailed-description">
            <p>At the moment, the honest truth is that there are currently no Private Servers for the Dragonflight expansion available. We are researching this topic a lot, and will continue to update this page when updates pop in, and when new servers announce specific release dates. But for now, there are no larger servers that have announced a release of Dragonflight</p>
            <p>The closest information we can provide at the moment is that Firestorm has announced that they are working on the Dragonflight expansion (10.x), and that they will release in 2025.</p> <br/>
            <p>Our recommendation is that you either play on a <Link className="a-underline-none" href="/wow/private-servers/">WoW Private Server</Link> meanwhile, or you simply pay retail and pay for a monthly subscription.</p>
            <p>Whether you decide to continue to Dragonflight through retail or a fanserver, we recommend you to get <Link href="/wow/addons/dragonflight-addons">Dragonflight Addons</Link>. This way you can optimize your gameplay.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >What is World of Warcraft: Dragonflight?</h2>
          <div className="detailed-description">
            <p>Dragonflight is an expansion running on patch 10.X+ made by Blizzard, who have also made many of the other famous expansions. The Dragonflight expansion was announced the 28th of April 2022, and was released at the 28th of November 2022. </p>
            <p>There has been a lot of focus on creating great content for this expansion. One of the purposes that Blizzard has made was that you should never be bored! Due to that, there is always something to do while being ingame, items to gather, achievements to complete, etc.</p>
            <p>Additionally, its quite easy to reach the end-game content. The leveling phase is not really a grind, and tends to be very easy. While this is a pro or a con is very subjective, depending if you like the hardcore gaming experience.</p>
            <p>If you need tips for DF, we have created a <Link href="/blog/dragonflight-leveling-guide-wow"> Dragonflight leveling guide</Link> that tells you how to efficiently level from 1-70. </p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Conclusion</h2>
          <div className="detailed-description">
            <p>Dragonflight is an exciting expansion for World of Warcraft, but currently there are no Private Servers running on this expansion just yet. Therefore, we recommend alternatives, such as earlier expansions, or playing retail.</p>
          </div>
        </Col>
      </Row>
    </div>
  </>
)

export default DragonflightExpansion
