import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Link from 'next/link'

const GeneralExpansion = () => (
  <>
    <div className="home-detailed-text-section">
      <Row className="d-flex mb-md-4">
        <Col sm={12} md={12}>
          <h2 className="zx-text-color-yellow fs-3 mb-2" >What is a WoW Private Server?</h2>
          <div className="detailed-description">
            <p>To begin, lets start from scratch. A WoW Private Server is a version of the original World of Warcraft, made by Blizzard. This means that through these free wow servers we list, you can play older and classic versions of World of Warcraft, that retail no longer offers, such as Vanilla, TBC, Wotlk, Cataclysm and more expansions.</p>

            <p>If you still wonder what a World of Warcraft Private Server is, you can consider it as a free-to-play and unauthorized version of the game. Unlike retail, these private servers are free and are mostly getting their money from donations. The server owners are private individuals, and not a company like Blizzard entertainment. Some private servers have however existed for many years, and are still stable and actively running.</p>

            <p>Therefore, we can conclude that these type of servers, are free-to-play, and may not necessarily be as strict as retail. The owner of the individual server set the rules themselves, and can also customize the game as they want. As a result, server-types can be very different.</p>

            <p>As you know by now, you can experience WoW Private Servers across different expansions, such as Cataclysm, Mist of Pandaria, Vanilla, and the rest. It&apos;s a whole new world to play and game on. We have combined a Toplist dedicated to World of Warcraft top 100, which are free wow game servers to play on.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex flex-row-reverse mb-md-4">
        <Col sm={12} md={12}>
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Should you play on a World of Warcraft Private Server?</h2>
          <div className="detailed-description">
            <p>That&apos;s a good question. To know whether or not it&apos;s something for you, you have to ask yourself this question:</p>

            <p>Are you fine with playing an unofficial version of the game?</p>

            <p>WoW Private Servers are complicated, but to sum it up, they aren&apos;t exactly fully legal, as some parts of the game can be considered as copyright that Blizzard can claim. The scene is however a grey area, hence many servers even today run without any issues or indications of closing by Blizzard. But it&apos;s still an ethical question; whether you are okay with playing a copyrighted/pirated version of World of Warcraft. Keep in mind that many of the World of Warcraft Private Servers have been running for ages, some since 2010. And there have never been reports of players getting in trouble for playing WoW Private Servers. At Zremax, we play on the Top World of Warcraft Servers too, found through list Top 100 list.</p>

            <p>If you are clear with that, then you should know that with a WoW private server, you can experience all the expansions, even if it&apos;s not appearing on retail. You can also experience different styles of the game, such as fun servers, blizzlike servers, customized versions, and much more. If you want to play on a high rated Wotlk Private Server, you can do so! The options are many, and that&apos;s one of the great things.</p>

            <p>Now that you have cleared with yourself, whether you want to play on a WoW Private Server, let&apos;s learn how to play on one.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex mb-md-4">
        <Col sm={12} md={12}>
          <h2 className="zx-text-color-yellow fs-3 mb-2" >How to play on a Private Server?</h2>
          <div className="detailed-description">
            <p>Playing on a WoW Private Server is not hard. You need a few initial set-up steps, and then you are almost ready to play. Read below, and learn how to do it.</p>

            <p>For a quick guide, you can see below.</p>

            1: The very first step is to find a server you will be playing on through our WoW Private Servers Toplist.
            <br />
            2: Visit the WoW Private Server through our website. Read details, and visit the website of the server, when you are ready.
            <br />
            3: Go to their Connection guide page, and note down their realmlist, and download the client for the expansion you have chosen to play on.
            <br />
            4: After downloading the WoW Client, head into the Realmlist, and paste the server realmlist in.
            <br />
            5: Open WoW.exe, and log in.
            <br />
            <br />
            <p>That&apos;s it. You&apos;re now ready to enjoy playing on a WoW Private Server!</p>
            <p>Note that we advise you to use <Link href="/wow/addons">WoW Addons</Link> when playing ingame as they will improve your gameplay heavily! A benefit is that you can level faster, improve your PvP and PvE play, and become better at dungeons and raids.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex flex-row-reverse pb-md-4">
        <Col sm={12} md={12}>
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Top 100 Private Server List for World of Warcraft </h2>
          <p className="detailed-description">
            At Zremax we care about the WoW Private Servers that are available.
            We want to list the best WoW Private Servers, and we want you, as a player, to play on the best WoW Private Servers. Therefore, we put an honor in delivering you the best results on our WoW Game Server List.
            We want to make sure you find your wanted server. With us, you can filter through expansions, features, server-type, and even search for specific keywords to find servers. You can also read reviews, and post reviews yourself – to help others.
            <br /> <br />
            We take the regular WoW Private Server Top 100 List definition to a whole new and modern level. No vote manipulation, no fake list, just a “bulletproof” list of actual servers. You can write server-reviews, rate the servers that are listed, and much more.
            <br /> At Zremax we like to see ourselves as a community related to World of Warcraft Private Servers and WoW Server Emulation.
            <br /> <br />
            If you&apos;re a server owner, and you want to take the extra mile, we offer premium WoW Private Server advertising. We do however of course also have the free listing options.
          </p>
        </Col>
      </Row>

      <Row className="d-flex mb-md-4">
        <Col sm={12} md={12}>
          <h2 className="zx-text-color-yellow fs-3 mb-2" >How many players are estimated to play on WoW Private Servers?</h2>
          <div className="detailed-description">
            <p>
              There aren&apos;t official numbers as to how many players are playing, but with a little research, it&apos;s possible to give an estimation, although it variates. It is previously estimated that around 1,3 million people are playing on WoW Private Servers. This estimation is however from when the famous Nostalrius server was available, which it no longer is. Therefore, we estimate that the number is a little less. Perhaps around 1 million active WoW Private Server players around the world, from international servers, to Russian, Spanish, and other local private servers. The truth is that there are a lot more WoW Private Servers than just the ones on the list, but not all are public and international. Many servers are made for just local countries and communities, that we don&apos;t know about.
              Some of the most popular private servers are servers as Warmane, which has around 5000-10000 players online, on their Wotlk realms, combining it to an amount up to around 30000 players throughout their realms and expansions.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="d-flex pb-md-4">
        <Col sm={12} md={12}>
          <h2 className="zx-text-color-yellow fs-3 mb-2" >What have you learned about WoW Private Servers now?</h2>
          <div className="detailed-description">
            <p>
            After reading through our page at Zremax, you now know what a WoW Private Server is. You have learned whether you should be playing on one, and you know there are many different wow servers to choose between. Luckily, on Zremax, we have a big WoW Toplist of the best servers, making it easier for you to find your favorite private server. We have also given you an estimation of how many players are playing, throughout the world. Lastly, you now know how to connect to a World of Warcraft Private Server, and that we have a community related to this scene, available for you at Zremax.
            </p>
          </div>
        </Col>
        </Row>

    </div>
  </>
)

export default GeneralExpansion
